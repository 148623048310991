import _ from 'lodash';

export function useAccess() {
    let writableWatchsites = [];
    let readableWatchsites = [];

    if (!_.isUndefined(localStorage.getItem("writable-watchsites"))) {
        writableWatchsites = JSON.parse(localStorage.getItem("writable-watchsites"));
    }
    if (!_.isUndefined(localStorage.getItem("readable-watchsites"))) {
        readableWatchsites = JSON.parse(localStorage.getItem("readable-watchsites"));
    }

    function canWrite(watchsite) {
        return writableWatchsites.includes(watchsite);
    }

    function canRead(watchsite) {
        return readableWatchsites.includes(watchsite) || canWrite(watchsite);
    }

    function setWritableWatchsites(data) {
        writableWatchsites = data;
        localStorage.setItem("writable-watchsites", JSON.stringify(writableWatchsites));
    }

    function setReadableWatchsites(data) {
        readableWatchsites = data;
        localStorage.setItem("readable-watchsites", JSON.stringify(readableWatchsites));
    }

    return [canWrite, canRead, writableWatchsites, readableWatchsites, setWritableWatchsites, setReadableWatchsites];
}