import React, { useCallback, useEffect, useState, useContext } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import DefaultLayout from "layouts/DefaultLayout";
import { useForm } from "shared/hooks/Form";
import { ReferentialContext } from 'shared/stores/Referential';
import SheetApi from 'shared/api/Sheet';
import AccountApi from 'shared/api/Account';
import LoadButton from 'shared/components/LoadButton';
import FieldComponent from 'shared/components/FieldComponent';
import FileComponent from 'shared/components/FileComponent';
import PageLoadingComponent from "shared/components/PageLoadingComponent";
import PreviewFieldComponent from "shared/components/PreviewFieldComponent";
import { compileErrors, compileDataToSelectOptions } from "shared/services/Utils";
import { prefixLinkTo } from "shared/services";
import { SHEET_ACTION_PRIVATE_AFFECT_WATCHSITE } from 'shared/data/SheetAction';
import { REFERENTIAL_TEXT_TYPE } from 'shared/data/ReferentialType';
import { denyIfCantPerform } from "shared/services/Can";
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';

export default function SheetPrivateForm(props) {

    const DEFAULT_TEXT_TYPE_LABEL = "Fiche client";

    const history = useHistory();
    const [canWrite,, writableWatchsites] = useAccess();

    denyIfCantPerform(props, "sheet:private:write", {writableWatchsites: writableWatchsites});

    const [data, errors, setErrors, setValue, setData,, formHasModifications, setHasModifications] = useForm({
        id: null,
        title: '',
        reglementary_text: null,
        publication_date: new Date(), // Date du jour par défault...
        text_date: new Date(), // Date du jour par défault...
        text_type: null,
        synthesis: '',
        categories: [],
        main_domain: null,
        main_subdomain: null,
        main_theme: null,
        watchsites: [],
    });
    
    const watchsiteMapper = (watchsite) => {
        return {
            "label": watchsite.label,
            "value": watchsite.id,
            "checked": data.watchsites.includes(watchsite.id),
            "disabled": !canWrite(watchsite.id)
        } 
    };
    const nodeMapper = (node) => {
        let children = node.childs.map(nodeMapper);
        children = children.concat(node.watchsites.map(watchsiteMapper));
        return {
            "label": node.label,
            "value": node.id,
            "children": children,
            "checked": false,
            "disabled": true,
        }
    };

    const [isLoad, setIsLoad] = useState(true);
    const [tree, setTree] = useState([]);
    const [accountTree, setAccountTree] = useState({'nodes': [], 'watchsites': []});
    const [availableDomains, setAvailableDomains] = useState([]);
    const [availableSubdomains, setAvailableSubdomains] = useState([]);
    const [availableThemes, setAvailableThemes] = useState([]);
    const [saving, setSaving] = useState(false);
    const [account] = useAccount();
    const [referentialContext] = useContext(ReferentialContext);
    
    // referential
    const domainTree = referentialContext["tree"];
    const textTypes = compileDataToSelectOptions(referentialContext[REFERENTIAL_TEXT_TYPE], 'id', 'value');

    const editMode = props.match.params.id ? true : false;

    const loadDependencies = useCallback(() => {

        // -- Text type by default
        if (data.text_type === null) {
            for (var tt in textTypes) {
                var textType = textTypes[tt];
                if (textType.label === DEFAULT_TEXT_TYPE_LABEL) {
                    setValue("text_type", textType.value, false);
                } 
            }
        } 

        AccountApi.getUserTree().then(at => setAccountTree(at));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        setTree(accountTree.nodes.map(nodeMapper).concat(accountTree.watchsites.map(watchsiteMapper)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.watchsites, accountTree]);

    useEffect(() => {
        if (editMode) {
            SheetApi
                .get(props.match.params.id, true)
                .then(freshSheet => {
                    let sheetData = {...freshSheet};

                    if (sheetData.text_types) sheetData.text_type = sheetData.text_types[0].id;
                    if (sheetData.categories) sheetData.categories = sheetData.categories.map(elem => elem.id);
                    if (sheetData.main_domain) sheetData.main_domain = sheetData.main_domain.id;
                    if (sheetData.main_subdomain) sheetData.main_subdomain = sheetData.main_subdomain.id;
                    if (sheetData.main_theme) sheetData.main_theme = sheetData.main_theme.id;
                    if (sheetData.publication_date) sheetData.publication_date = new Date(sheetData.publication_date);
                    if (sheetData.text_date) sheetData.text_date = new Date(sheetData.text_date);

                    setData(d => Object.assign({}, d, sheetData));
                    setIsLoad(false);
                })
                .catch(() => {
                    toast.error("Une erreur est survenue lors de la récupération de la fiche");
                    history.goBack();
                });
        } else {
            setIsLoad(false);
        }
        loadDependencies();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadDependencies, props, setData, editMode])

    useEffect(() => {
        let newDomains = [];
        let newSubDomains = [];
        let newThemes = [];

        const mapper = (item, parent = null) => {
            if (item.children) {
                item.children = item.children.map(i => mapper(i, item));
            }
            if (data.categories.includes(item.value)) {
                // eslint-disable-next-line default-case
                switch (item.type) {
                    case 1: 
                        if (!newDomains.includes(item)) {
                            newDomains.push(item);
                        }
                        break;
                    case 2: 
                        if (!newSubDomains.includes(item) && parent.value === data.main_domain) {
                            newSubDomains.push(item);
                        }
                        break;
                    case 3: 
                        if (!newThemes.includes(item) && parent.value === data.main_subdomain) {
                            newThemes.push(item);
                        }
                        break;
                }
            }
            return item;
        }; 

        domainTree.map(mapper);

        setAvailableDomains(newDomains);
        setAvailableSubdomains(newSubDomains);
        setAvailableThemes(newThemes);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.categories, data.main_domain, data.main_subdomain]);

    function categoriesChange(values) {
        let newValues = [];

        const mapper = (item) => {
            if (item.children) {
                item.children = item.children.map(mapper);
                item.children.forEach(child => {
                    if (!newValues.includes(item.value)
                        && (values.includes(child.value) || newValues.includes(child.value))
                    ) {
                        newValues.push(item.value);
                     }
                });
            }
            if (values.includes(item.value) && !newValues.includes(item.value)) {
                newValues.push(item.value);  
            }

            return item;
        }; 

        domainTree.map(mapper);

        setValue("categories", newValues);
    }

    function submit(event) {
        event.preventDefault();
        if (event.target.id === "form-private-sheet") {
            const submitButton = event.nativeEvent.submitter.name;
            const actionId = SHEET_ACTION_PRIVATE_AFFECT_WATCHSITE;

            setSaving(true);

            let formData = {...data};
            formData.action = actionId;
            formData.reglementary_text = data.reglementary_text ? data.reglementary_text.hash : null;
            formData.text_types = [formData.text_type];
            formData.confidential_account = account.id;

            SheetApi
                .save(formData, true)
                .then(([message, sheet]) => {
                    setData(Object.assign({}, data, {"id": sheet.id}));
                    setErrors({});
                    setSaving(false);
                    toast.success(message);
                    setHasModifications(false);
                    if (event.nativeEvent.submitter.name === "save-and-close") {
                        props.history.push(prefixLinkTo() + '/sheets-affected');
                    } else {
                        props.history.push(prefixLinkTo() + '/sheets/private/' + sheet.id);
                    }                    
                })
                .catch(error => {
                    setSaving(false);

                    const errordata = error.response.data;

                    if (errordata.message) {
                        toast.error(errordata.message);
                    }

                    if(errordata.errors) {
                        setErrors(compileErrors(errordata.errors));
                    }
                });
            
            setSaving(submitButton);
        }
    }

    const pageName = editMode ? 'Edition fiche privative' : 'Création fiche privative';

    if (isLoad === true) {
        return <PageLoadingComponent label={pageName} />
    }

    return (
        <DefaultLayout screen="E53" title={pageName}>
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>{pageName}</h1>

            <section className="bloc">
                <form onSubmit={submit} id="form-private-sheet" className="form">
                    <section id="configuration" className="bloc">
                        <header className="bg-gris-45 uppercase">Fiche privative</header>

                        <section className="border-gris-25 border-lrb bg-blanc">
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Titre *"
                                    name="title"
                                    value={data.title}
                                    onChange={value => setValue("title", value)}
                                    error={errors.title}
                                />
                                <FileComponent
                                    label="Texte réglementaire (PDF) *"
                                    extensions={[".pdf"]}
                                    value={data.reglementary_text}
                                    name="reglementary_text"
                                    onChange={value => setValue('reglementary_text', value)}
                                    upload={SheetApi.uploadReglementaryText}
                                    error={errors.reglementary_text}
                                    showSize
                                />
                                <FieldComponent
                                    label="Date de publication"
                                    type="date"
                                    name="publication_date"
                                    className="field small"
                                    value={data.publication_date}
                                    error={errors.publication_date}
                                    onChange={value => setValue("publication_date", value)}
                                />
                                <FieldComponent
                                    label="Date du texte"
                                    type="date"
                                    name="text_date"
                                    className="field small"
                                    value={data.text_date}
                                    error={errors.text_date}
                                    onChange={value => setValue("text_date", value)}
                                />
                                <FieldComponent
                                    label="Type de texte"
                                    type="select"
                                    name="text_type"
                                    className="field small"
                                    options={textTypes}
                                    value={data.text_type}
                                    clearable
                                    error={errors.text_type}
                                    onChange={value => setValue("text_type", value)}
                                />
                            </div>
                            <div className="bg-gris-10">
                                <FieldComponent
                                    label="Synthèse"
                                    type="textarea"
                                    rte
                                    name="synthesis"
                                    value={data.synthesis}
                                    error={errors.synthesis}
                                    onChange={value => setValue("synthesis", value)}
                                    className="field h-300px"
                                />
                            </div>
                            <div className="bg-blanc">                                
                                <FieldComponent 
                                    type="dropdown-tree-select"
                                    label="Domaines / Sous domaines / Thèmes *"
                                    name="categories"
                                    data={domainTree}                     
                                    mode="hierarchical"
                                    error={errors.categories}
                                    value={data.categories}
                                    onChange={categoriesChange}
                                />
                                <div className="row">
                                <div className="col-md-4">
                                        <FieldComponent
                                            label="Domaine principal *"
                                            name="main_domain"
                                            className="field small"
                                            value={data.main_domain}
                                            type="select"
                                            options={availableDomains}
                                            error={errors.main_domain}
                                            onChange={value => setValue("main_domain", value)}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FieldComponent
                                            label="Sous-domaine principal *"
                                            name="main_subdomain"
                                            className="field small"
                                            value={data.main_subdomain}
                                            type="select"
                                            options={availableSubdomains}
                                            error={errors.main_subdomain}
                                            onChange={value => setValue("main_subdomain", value)}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FieldComponent
                                            label="Thème principal"
                                            name="main_theme"
                                            className="field small"
                                            value={data.main_theme}
                                            type="select"
                                            options={availableThemes}
                                            error={errors.main_theme}
                                            onChange={value => setValue("main_theme", value)}
                                        />
                                    </div>
                                </div>


                                
                                {editMode && (
                                    <PreviewFieldComponent label="Points de veilles">
                                        {data.watchsites.map(watchsite => watchsite.label).join(', ')}
                                    </PreviewFieldComponent>
                                )}
                                {!editMode && (
                                    <FieldComponent
                                        label="Points de veille *"
                                        type="dropdown-tree-select"
                                        name="watchsites"
                                        className="field medium"
                                        value={data.watchsites}
                                        error={errors.watchsites}
                                        data={tree}
                                        mode="hierarchical"
                                        onChange={values => setValue("watchsites", values)}
                                        updateMapperValues={false}
                                    />
                                )}
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <div className="col-md-12 text-right">
                            <LoadButton 
                                loading={saving} 
                                label="Enregistrer"
                                name="save"
                                id="save"
                            />
                            <LoadButton 
                                loading={saving} 
                                label="Enregistrer et fermer"
                                name="save-and-close"
                                id="save-and-close"
                            />
                        </div>
                    </section>
                </form>
            </section>
        </DefaultLayout>
    )
}